const info = [
  {
    key: 0,
    numero: 10,
    concepto: "años",
    texto:
      "Haciendo felices y enseñando a niños en actividades extraescolares, excursiones y escuelas de vacaciones."
  },
  {
    key: 1,
    numero: 150,
    concepto: "personas",
    texto:
      "Administrativos, monitores, técnicos, educadores, pedagogos, coordinadores, comerciales, etc."
  },
  {
    key: 2,
    numero: 50,
    concepto: "actividades",
    texto: "Para que los niños aprendan, disfruten y sean felices."
  },
  {
    key: 3,
    numero: 230,
    concepto: "colegios",
    texto: "ya confían en nosotros, ¿y tú?"
  },
  {
    key: 4,
    numero: 1500,
    concepto: "alumnos",
    texto: "disfrutan diariamente con nuestras actividades."
  }
];

export default info;
