import React from "react";

import "./style.scss";
import Helmet from "./common/helmet";
import Header from "./common/header";
import MainLayout from "./layouts/index/mainLayout";
import Footer from "./common/footer";
import NavBar from "./common/navbar";

const Layout = ({ children }) => (
  <>
    <Helmet />
    <NavBar />
    <Header />
    <MainLayout />
    <Footer />
  </>
);

export default Layout;
