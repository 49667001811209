import React from "react";
import "../style.scss";

import aristasLogo from "../../images/aristas-logo.png";
import SubMenu from "./submenu";

const Header = ({ siteTitle }) => (
  <section className="hero heroBg is-fullheight-with-navbar">
    <SubMenu />
    <div className="hero-body">
      <div className="container center">
        <article className="media">
          <figure className="image is-left is-128x128">
            <img src={aristasLogo} alt="Aristas-logo" />
          </figure>
          <div className="media-content">
            <div className="content">
              <h1 className="is-uppercase is-size-1 has-text-white">Aristas</h1>
              <p className="subtitle has-text-white is-size-3">
                ¡Diviértete aprendiendo!
              </p>
            </div>
          </div>
        </article>
      </div>
    </div>
  </section>
);

export default Header;
