import React from "react";

import Servicios from "./layout/services";
import Stats from "./layout/stats";
import SaberMas from "./layout/sabermas";

import Info from "./datos/datosStats";

const MainLayout = ({ children }) => (
  <div>
    <Servicios />
    <Stats data={Info} />
    <SaberMas />
  </div>
);

export default MainLayout;
