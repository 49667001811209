import React from "react";

import "./stats.scss";

const Stats = ({ data }) => (
  <section className="section statsBg">
    <div className="container">
      <h1 className="title is-2 has-text-centered">Nos gusta lo que hacemos</h1>
      <div className="columns">
        {data.map(item => (
          <div
            className="column is-offset-1 has-background-white-bis con-borde-abajo-izquierda"
            key={item.key}
          >
            <article className="media con-borde-arriba-derecha">
              <div className="media-content">
                <div className="content has-text-centered ">
                  <h1 className="title is-size-4 has-text-centered">
                    +{item.numero}
                  </h1>
                  <p className="subtitle is-size-5">{item.concepto}</p>
                  <p className="subtitle is-size-6">{item.texto}</p>
                </div>
              </div>
            </article>
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default Stats;
