import React from "react";

import "./services.scss";

const datos = [
  {
    key: 0,
    titulo: "Excursiones",
    subtitulo: "¡El día más divertido del año!",
    texto: `
      Para nosotros, las excursiones son la oportunidad de poner en
      marcha todos nuestros sueños, investigaciones, deseos e ilusiones para un
      futuro mejor, construyendo un día inolvidable, un recuerdo que siempre
      arranque una sonrisa a lo largo de la vida de quienes nos conocen.
    `
  },
  {
    key: 1,
    titulo: "Extraescolares",
    subtitulo: "Mejorando el día a día",
    texto: `
      Ofrecemos una gran variedad de actividades para hacer
      posible la implementación de nuevas áreas de conocimiento
      para los más pequeños al tiempo que, mientras ellos se
      divierten, las familias puedan construir un horario adaptado
      a su vida laboral y personal.
    `
  },
  {
    key: 2,
    titulo: "Escuelas de vacaciones",
    subtitulo: "Primavera, Verano y Navidad",
    texto: `
      A veces, la jornada laboral guarda poco en común con el
      horario escolar de nuestros hijos e hijas. Por ello, de
      manera habitual muchos padres sufren un auténtico quebradero
      de cabeza al encontrase sin opciones en el momento de
      conciliar ambos horarios.
    `
  }
];

const Services = () => (
  <div>
    <section className="section serviciosBg">
      <div className="container">
        <div className="columns">
          {datos.map(item => (
            <div
              className="column is-3 is-offset-1 has-background-white-bis con-borde-abajo-izquierda"
              key={item.key}
            >
              <article className="media con-borde-arriba-derecha">
                <div className="media-content">
                  <div className="content has-text-centered">
                    <h1 className="title is-size-4">{item.titulo}</h1>
                    <p className="subtitle is-size-5">{item.subtitulo}</p>
                    <p className="subtitle is-size-6">{item.texto}</p>
                  </div>
                </div>
              </article>
            </div>
          ))}
        </div>
      </div>
    </section>
  </div>
);

export default Services;
