import React from "react";

const SaberMas = () => (
  <section className="section">
    <div className="container">
      <h1 className="title is-2 has-text-centered">
        Así que quieres saber más de nosotros...
      </h1>
      <div className="columns">
        <div className="column is-8 is-offset-2">
          <article className="media">
            <div className="media-content">
              <div className="content">
                <p className="subtitle is-size-4">
                  ...perfecto. Ante todo, darte la bienvenida. No estás en la
                  página de una empresa más de excursiones. Te encuentras a las
                  puertas de conocer un sueño hecho realidad: Aristas es el
                  resultado de arriesgarse por trabajar en lo que más nos gusta.
                  Aristas es un proyecto poliédrico en el que cada miembro del
                  equipo es parte de algo más grande: La idea de construir un
                  ocio educativo y vivencial, basado en el descubrimiento, la
                  experimentación y la diversión. Aristas nace de personas con
                  una amplia experiencia en el sector de la educación, el ocio y
                  el tiempo libre. Durante años, el equipo de Aristas da vida a
                  sus ideas, convirtiéndolas en propuestas educativas, para que
                  los niños disfruten mientras aprenden.
                </p>
              </div>
              <div className="buttons are-large is-centered">
                <a className="button is-danger" href="/nosotros">Conócenos</a>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  </section>
);

export default SaberMas;
